exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-notice-tsx": () => import("./../../../src/pages/privacy-notice.tsx" /* webpackChunkName: "component---src-pages-privacy-notice-tsx" */),
  "component---src-pages-product-centrix-diagnostics-platform-tsx": () => import("./../../../src/pages/product/centrix-diagnostics-platform.tsx" /* webpackChunkName: "component---src-pages-product-centrix-diagnostics-platform-tsx" */),
  "component---src-pages-product-s-lx-tsx": () => import("./../../../src/pages/product/s-lx.tsx" /* webpackChunkName: "component---src-pages-product-s-lx-tsx" */),
  "component---src-pages-product-sa-380-it-tsx": () => import("./../../../src/pages/product/sa380-it.tsx" /* webpackChunkName: "component---src-pages-product-sa-380-it-tsx" */),
  "component---src-pages-product-sa-380-tsx": () => import("./../../../src/pages/product/sa380.tsx" /* webpackChunkName: "component---src-pages-product-sa-380-tsx" */),
  "component---src-pages-product-sa-380-tx-tx-l-tsx": () => import("./../../../src/pages/product/sa380tx-tx-l.tsx" /* webpackChunkName: "component---src-pages-product-sa-380-tx-tx-l-tsx" */),
  "component---src-pages-product-vti-21-tsx": () => import("./../../../src/pages/product/vti21.tsx" /* webpackChunkName: "component---src-pages-product-vti-21-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */),
  "component---src-pages-service-interlocking-relay-monitoring-replay-fault-detection-tsx": () => import("./../../../src/pages/service/interlocking-relay-monitoring-replay-fault-detection.tsx" /* webpackChunkName: "component---src-pages-service-interlocking-relay-monitoring-replay-fault-detection-tsx" */),
  "component---src-pages-service-level-crossing-event-monitoring-tsx": () => import("./../../../src/pages/service/level-crossing-event-monitoring.tsx" /* webpackChunkName: "component---src-pages-service-level-crossing-event-monitoring-tsx" */),
  "component---src-pages-service-points-condition-monitoring-tsx": () => import("./../../../src/pages/service/points-condition-monitoring.tsx" /* webpackChunkName: "component---src-pages-service-points-condition-monitoring-tsx" */),
  "component---src-pages-service-power-supply-monitoring-tsx": () => import("./../../../src/pages/service/power-supply-monitoring.tsx" /* webpackChunkName: "component---src-pages-service-power-supply-monitoring-tsx" */),
  "component---src-pages-service-tdm-ssi-serial-data-monitoring-tsx": () => import("./../../../src/pages/service/tdm-ssi-serial-data-monitoring.tsx" /* webpackChunkName: "component---src-pages-service-tdm-ssi-serial-data-monitoring-tsx" */),
  "component---src-pages-service-track-circuit-condition-monitoring-tsx": () => import("./../../../src/pages/service/track-circuit-condition-monitoring.tsx" /* webpackChunkName: "component---src-pages-service-track-circuit-condition-monitoring-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-case-study-listing-tsx": () => import("./../../../src/templates/case-study-listing.tsx" /* webpackChunkName: "component---src-templates-case-study-listing-tsx" */),
  "component---src-templates-case-study-post-tsx": () => import("./../../../src/templates/case-study-post.tsx" /* webpackChunkName: "component---src-templates-case-study-post-tsx" */),
  "component---src-templates-news-listing-tsx": () => import("./../../../src/templates/news-listing.tsx" /* webpackChunkName: "component---src-templates-news-listing-tsx" */),
  "component---src-templates-news-post-tsx": () => import("./../../../src/templates/news-post.tsx" /* webpackChunkName: "component---src-templates-news-post-tsx" */)
}

